@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat-palette($mat-light-blue);
$candy-app-accent: mat-palette($mat-amber, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat-palette($mat-red);

.color-primary {
    color: mat-color($candy-app-primary) !important;
}

.bg-color-primary {
    background-color: mat-color($candy-app-primary) !important;
}

.color-accent {
    color: mat-color($candy-app-accent);
}
.bg-color-accent {
    background-color: mat-color($candy-app-accent) !important;
}

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat-light-theme(
    $candy-app-primary,
    $candy-app-accent,
    $candy-app-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);
